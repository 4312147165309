import { Field, Label } from '@headlessui/react';
import { FC, useEffect, useState } from 'react';

import { Checkbox } from '../../../components/Checkbox';

type FilterItemProps = {
  isSelectedAll: boolean;
  id: string;
  onChange: (state: boolean) => void;
  name: string;
  checked: boolean;
};

export const FilterItem: FC<FilterItemProps> = ({ isSelectedAll, id, onChange, name, checked }) => {
  const [isSelected, setSelected] = useState(checked);

  useEffect(() => {
    setSelected(isSelectedAll);
  }, [isSelectedAll]);

  return (
    <Field
      className="relative flex cursor-pointer items-center gap-2 text-sm text-gray-700"
      key={id}
    >
      <Checkbox
        checked={isSelected}
        id={`select-company-${id}`}
        onChange={(checked) => {
          onChange(checked);
          setSelected(checked);
        }}
      />
      <span className="max-w-[280px] truncate text-nowrap">{name}</span>
      <Label
        className="absolute left-0 top-0 h-full w-full cursor-pointer"
        htmlFor={`select-company-${id}`}
      />
    </Field>
  );
};
