import { Field, Label } from '@headlessui/react';
import { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ChevronDownIcon, CloseIcon, FilterIcon, SearchIcon } from '../../../assets/icons';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { Input } from '../../../components/Input';
import Loader from '../../../components/Loader';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { CompanyHooks, useModalState, useScreenSize } from '../../../hooks';
import { CompanyMobileFilter } from './CompanyMobileFilter';
import { FilterItem } from './FilterItem';

export type CompanyFilterProps = {
  onSubmit: (selectedIds: string[], exceptSelectedIds: string[]) => void;
};
const take = 20;

export const CompanyFilter: FC<CompanyFilterProps> = ({ onSubmit }) => {
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [companyIds, setCompanyId] = useState<string[]>([]);
  const [exceptCompanyIds, setExceptCompanyId] = useState<string[]>([]);

  const handleDeselectCompany = (id: string) => {
    if (isSelectedAll) setExceptCompanyId((prev) => [...prev, id]);
    setCompanyId((prev) => prev.filter((selectedId) => selectedId !== id));
  };

  const handleSelectCompany = (id: string) => {
    if (exceptCompanyIds.find((exceptId) => exceptId === id))
      setExceptCompanyId((prev) => prev.filter((exceptId) => exceptId !== id));

    if (!isSelectedAll) setCompanyId((prev) => [...prev, id]);
  };

  const handleSelectAllCompanies = () => {
    setExceptCompanyId([]);
    setCompanyId(companies?.map(({ id }) => id) || []);
  };

  const handleDeselectAllCompanies = () => {
    setExceptCompanyId([]);
    setCompanyId([]);
    setSelectedAll(false);
  };
  const handleReset = () => {
    setExceptCompanyId([]);
    setCompanyId([]);
    setSelectedAll(false);
  };
  const { isOpen, handleCloseModal, toggler, handleOpenModal } = useModalState();
  const [search, setSearch] = useState('');
  const { companies, fetchNextPage, hasNextPage, totalPages } = CompanyHooks.useCompanies({
    search,
    take,
  });

  const { isMobile } = useScreenSize();
  return isMobile ? (
    <>
      <Button
        className="centered flex !size-9 h-fit w-fit rounded border border-gray-100 bg-white"
        onClick={handleOpenModal}
        styleType="NONE"
      >
        <FilterIcon />
      </Button>
      <CompanyMobileFilter
        companyIds={companyIds}
        exceptCompanyIds={exceptCompanyIds}
        handleApplyFilters={() => {
          onSubmit(companyIds, exceptCompanyIds);
          handleCloseModal();
        }}
        handleDeselectAllCompanies={handleDeselectAllCompanies}
        handleDeselectCompany={handleDeselectCompany}
        handleResetFilters={handleReset}
        handleSelectAllCompanies={handleSelectAllCompanies}
        handleSelectCompany={handleSelectCompany}
        isOpen={isOpen}
        isSelectedAll={isSelectedAll}
        setSelectedAll={setSelectedAll}
        toggler={toggler}
      />
    </>
  ) : (
    <Popover onOpenChange={toggler} open={isOpen}>
      <PopoverTrigger className="flex h-9 w-fit items-center gap-3 rounded border border-gray-100 px-3 py-2 text-sm font-[450] text-gray-700 shadow-xs">
        <span className="max-w-[350px] truncate text-nowrap">
          Company:{' '}
          {companyIds.length === 0
            ? 'All'
            : `${companies.find(({ id }) => companyIds[0] === id)?.name} ${Math.max(companyIds.length - 1, 0) > 0 ? `+ ${Math.max(companyIds.length - 1, 0)} more` : ''}`}
        </span>
        <ChevronDownIcon />
      </PopoverTrigger>
      <PopoverContent className="flex w-[350px] flex-col rounded-lg bg-gray-100 p-0 font-normal shadow-2xl">
        <div className="flex h-fit w-full flex-col gap-3 rounded-lg bg-white p-4 pt-3">
          <div className="flex w-full justify-between">
            <span className="text-xl font-semibold text-gray-700">Company</span>
            <PopoverClose>
              <CloseIcon className="size-3" />
            </PopoverClose>
          </div>
          <Input
            icon={<SearchIcon />}
            onChange={setSearch}
            value={search}
            wrapperClassName="h-10 w-full"
          />
          <div className="flex h-fit max-h-[300px] w-full flex-col gap-2 overflow-y-auto">
            <Field className="relative flex cursor-pointer items-center gap-2 text-sm text-gray-700">
              <Checkbox
                checked={isSelectedAll}
                id="select-all-companies"
                onChange={(checked) => {
                  setSelectedAll(checked);
                  checked ? handleSelectAllCompanies() : handleDeselectAllCompanies();
                }}
              />
              <span>All Companies ({totalPages * take})</span>
              <Label
                className="absolute left-0 top-0 h-full w-full cursor-pointer"
                htmlFor="select-all-companies"
              />
            </Field>
            <InfiniteScroll
              className="flex h-fit w-full flex-col items-stretch justify-start gap-2 max-lg:justify-center"
              dataLength={companies.length}
              hasMore={hasNextPage}
              loader={<Loader />}
              next={fetchNextPage}
              scrollableTarget="scrollbar-target"
            >
              {companies.map(({ name, id }) => (
                <FilterItem
                  checked={[...companyIds, ...exceptCompanyIds].includes(id)}
                  id={id}
                  isSelectedAll={isSelectedAll}
                  key={id}
                  name={name}
                  onChange={(checked) =>
                    checked ? handleSelectCompany(id) : handleDeselectCompany(id)
                  }
                />
              ))}
            </InfiniteScroll>
          </div>
        </div>
        <div className="flex w-full items-center justify-between p-4">
          <Button
            className="h-fit w-fit text-sm font-[450] text-gray-700"
            onClick={handleCloseModal}
            styleType="NONE"
          >
            Cancel
          </Button>
          <div className="flex h-9 w-fit gap-3">
            <Button
              className="h-full w-fit rounded border border-gray-300 bg-white px-4 text-sm font-[450] text-gray-700"
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              className="h-full w-fit px-4 text-sm font-[550] text-white"
              onClick={() => {
                onSubmit(companyIds, exceptCompanyIds);
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
